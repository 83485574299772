@import '../../Datas/ProjectDatas/ProjectValues.scss';

.colorContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.modalimportCSV {
  background-color: white;
  z-index: 5555;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-35%, -65%);
  padding: 30px;
  border: 2px solid var(--main-color);
  border-radius: 5px;

  &__danger {
    background-color: red;
    z-index: 5555;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-35%, -65%);
    padding: 30px;
    border: 2px solid black;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 700;
  }
}

.dragCSV {
  // width: 70%;
  display: flex;
  justify-content: center;
  padding: 30px;
}

.btnModalImportCSV {
  margin: 5px;
}

.adminSecondToolBar {
  padding: 20pt;
}

.container-btn-change-name {
  display: flex;
}

.newElementContainer {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.container {
  margin: 20pt;
}

.newElementColumn {
  height: 100%;
  // flex: 0 0 48%;
  // background-color: white;
  flex: 1;
  max-width: 50%;
  // border: 1px solid lightgrey;
  // border-radius: 5px;
  margin: 0 5pt;
}

.circleColorBusLine {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
}

.btn-add-column {
  background-color: #147076;
  border-top-right-radius: 5px;
  // border-bottom-right-radius: 5px;
  cursor: pointer;
}

.btn-add-colum-text-container {
  margin: 10pt;
}

.separator {
  border-bottom: 1px solid lightgrey;
  margin: 0px 20px;
}

// .containerTabStopBus {
//   border: 1px solid lightgrey;
//   border-radius: 5px;
//   margin: 0 15pt 15pt 15pt;
//   padding: 5px;
// }
.tdBorder {
  border: 1px solid lightgrey;
}

.btn-add-column-text {
  color: white;
  padding: 0;
  margin: 0;
  font-size: 0.8em;
  font-weight: bold;
  text-align: center;
}

.btn-display {
  background-color: transparent;
  border: none;
  color: black;
  cursor: pointer;
}

.container-btn-display {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px 5px 5px;
}

.containerStop {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 5pt;
}

.tabContainerScroll {
  overflow: auto;
}

.scroll {
  max-height: 300px;
  overflow: scroll;
}

::-webkit-scrollbar {
  // -webkit-appearance: none;
  width: 10px;
}

.textItemDrag {
  padding: 0;
  margin: 0 0 0px 5px;
  text-align: left;
}

.iconDragNDrop {
  width: 20px;
  height: 20px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.labelStop {
  display: flex;
  align-items: flex-start;
  text-align: left;
}

.titleContainer {
  padding: 10pt 20pt;
  text-align: left;
  margin: 0;
  font-weight: 600;
  display: flex;
}

.textNewElementProperty {
  padding: 10pt 15pt;
  text-align: left;
  margin: 0;
  font-weight: 700;
}

.subtitle {
  font-size: 13px;
  font-weight: 600;
}

.inputContainer {
  margin: 15pt;

  &-FullWidth {
    margin: 15pt;
    width: 100%;
  }
}

.d-flex {
  display: flex;
}

.parking_places_type {
  display: flex;
  justify-content: space-around;
}

.parking_dropdown {
  margin-right: 20px;
  display: flex;
  justify-content: space-between;
  width: 250px;
  align-items: center;
}

.parking_date {
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;
  align-items: center;
}

.parking_creneau {
  margin-right: 5px;
}

.parking_schedule {
  width: 50px;

  input {
    padding: 5px;
  }
}

.parking_column {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
}

.parking_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.parking_row_wide {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  div {
    margin: 5px;
  }
}

.parking_buttons_row_container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  Button {
    margin: 5px;
  }
}

.h1 {
  font-size: 1.2em;
  color: 'black';
  font-weight: bold;
  margin-right: 3px;
}

.newElementProperty {
  padding: 15pt;
  height: 100%;
}

.select {
  border-radius: 5px;
  padding: 5px;
}

.space-between {
  justify-content: space-between;
}

h1 {
  color: var(--main-color);
}

h3 {
  font-weight: 500;
}

.iconLine {
  height: 1.3em;
  width: auto;
  margin-right: 0.5em;
  margin-left: 1em;
  // display: flex;
}

.labelBus {
  display: flex;
  justify-content: space-between;
}

.labelBus select {
  border-radius: 5px;
  padding: 5px;
  flex: 1;
  margin-left: 10pt;
  margin-bottom: 10pt;
}

.sidebarLogo {
  height: 1.3em;
  width: auto;
  margin-right: 0.5em;
  margin-left: 1em;
}

.terminusContainer {
  border: 1px solid lightgrey;
  border-radius: 5px;
  margin-bottom: 10pt;
}

.researchButton {
  position: absolute;
  height: 100%;
}

.coordsTerminus {
  margin: 10pt 0;
}

.btnContainer {
  display: flex;
  justify-content: space-between;
}

.tdBlue {
  background-color: var(--main-color);
  color: white;
  text-align: left;
  font-weight: 500;
  padding: 2px 10px;
}

.tdGreen {
  background-color: #147076;
  color: white;
  text-align: left;
  font-weight: 500;
  padding: 2px 10px;
}

.btnContainerValidation {
  display: flex;
  justify-content: left;
  margin: 20px;
}

.btnValidation {
  background-color: var(--main-color);
  margin-right: 10px;
}

.btnCancel {
  background-color: #147076;
}

.tableCustom {
  margin: 20px;
}

.btnStyle {
  color: white;
  font-weight: bold;
  border-radius: 5px;
  border: none;
  padding: 10px 100px;
  cursor: pointer;
}

.btn {
  margin: 0 15px;
}

.CheckboxRecurantNotification {
  margin-bottom: auto;
}

.textWithIcon {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.normalRow {
  background-color: rgb(255, 255, 255);
}

.selectedRow {
  background-color: #e1f5fe;
}

.InputElement {
  margin: 18pt;
  margin-left: 22pt;
  margin-right: 22pt;
}