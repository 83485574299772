#legal-mentions {
  text-align: justify;
  padding: 10px;
  h1 {
    text-align: center;
  }

  h2 {
    text-align: center;
  }

  a {
    color: #5d95cf;
    text-decoration: none;
  }
}
