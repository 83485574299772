.CardTitle {
  margin: 5pt 8pt 1pt;
  text-align: start;
  font-size: 12pt;
  color: var(--main-color);
}

.CardSubTitle {
  margin: 1pt 8pt;
  text-align: start;
}

.CardBody {
  margin: 3pt;
  font-size: 11pt;
}

.Card {
  margin: 15pt;
}

.CardContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
}
