.adminToolbarContainer {
  display: flex;
  justify-content: space-between;
  padding: 20pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

.adminContainer {
  padding: 10px;
  margin: 20pt;
  margin-top: 10pt;
  max-width: 100%;
  border-radius: 6pt;
  // box-shadow: 4pt 4pt 2pt lightgrey;
  box-shadow: 0px 21px 40px 3px lightgrey;
  background-color: white;
}

// invisible bar containing both title button and login button
.adminToolbarElement {
  background-color: white;
  border-radius: 6pt;
  box-shadow: 4pt 4pt 2pt lightgrey;
}

// button at thetop left corner of the screen
.adminMiniTitle {
  align-self: flex-start;
}

// button at the top right corner of the screen
.adminMiniLogin {
  align-self: flex-end;
}

// the menu which open when clicking on adminMiniLogin
.adminLoginMenu {
  padding: 10pt;
}

// logo at the top left corner of the screen
.sss_logo {
  max-width: 80%;
  padding: 10%;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  fill: rgb(255, 255, 255);
}

.logo {
  width: 50%;
}