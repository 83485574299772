.ElementProperty {
  padding: 10pt;
  height: 100%;
}

.adminPropertyName {
  margin: 1pt;
  text-align: left;
  font-size: 12pt;
  padding: 0pt;
}
.adminPropertyValue {
  margin: 1pt;
  margin-left: 20pt;
  text-align: left;
  font-size: 14pt;
  color: #1976d2;
}
